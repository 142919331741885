import React from "react";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
const Login = React.lazy(() => import("@/pages/login"));
const Main = React.lazy(() => import("@/pages/main"));
const Index = React.lazy(() => import("@/pages/index"));
const ChildCompany = React.lazy(() => import("@/pages/child-company"));
const PersonnelAdmin = React.lazy(() => import("@/pages/personnel-admin"));
const CompanyLib = React.lazy(() => import("@/pages/SafetyLib/company-lib"));
const QajdLib = React.lazy(() => import("@/pages/SafetyLib/aqjd-lib"));
const CourseAdmin = React.lazy(() => import("@/pages/training/course-admin"));
const DucRecords = React.lazy(() => import("@/pages/training/duc-records"));
const PeopleFile = React.lazy(() => import("@/pages/training/people-file"));
const TopicAdmin = React.lazy(() => import("@/pages/training/topic-admin"));
const CreatePaper = React.lazy(() => import("@/pages/training/create-paper"));
const PaperSend = React.lazy(() => import("@/pages/training/paper-send"));
const CreateCourse = React.lazy(() => import("@/pages/training/create-course"));
const EditCourse = React.lazy(() => import("@/pages/training/edit-course"));
const MyCourse = React.lazy(() => import("@/pages/training/my-course"));
const TarningRecord =  React.lazy(() => import("@/pages/training/tarning-record"));
const ApprovalPaper =  React.lazy(() => import("@/pages/training/approval-paper"));
const CourseDis = React.lazy(() => import("@/pages/training/course-distribution"));
const CourseAutoDelivery = React.lazy(() => import("@/pages/training/course-auto-delivery"));
const ENotify = React.lazy(() => import("@/pages/admin-utils/e-notify"));
const SignResponsibility = React.lazy(() => import("@/pages/admin-utils/sign-responsibility"));
const Issued = React.lazy(() => import("@/pages/admin-utils/issued"));
const ExamBug = React.lazy(() => import("@/pages/admin-utils/exam-bug"));
const ExamGoods = React.lazy(() => import("@/pages/admin-utils/exam-goods"));
const cityBus = React.lazy(() => import("@/pages/admin-utils/city-bus"));
const CreateWindControl = React.lazy(() => import("@/pages/admin-utils/create-wind-control"));
const ListWindControl = React.lazy(() => import("@/pages/admin-utils/list-wind-control"));
const WayInfo = React.lazy(() => import("@/pages/ele-way/1-Info"));
const WayReceiver = React.lazy(() => import("@/pages/ele-way/2-Receiver"));
const WayLoader = React.lazy(() => import("@/pages/ele-way/3-Loader"));
const WaySendAddress = React.lazy(() => import("@/pages/ele-way/4-Send-Address"));
const WayTankInfo = React.lazy(() => import("@/pages/ele-way/5-Tank-Info"));
const WayGoodsInfo = React.lazy(() => import("@/pages/ele-way/6-Goods-Info"));
const WayPractitionersInfo = React.lazy(() => import("@/pages/ele-way/7-Practitioners-Info"));
const WayTrailerInfo = React.lazy(() => import("@/pages/ele-way/8-Trailer-Info"));
const WayCarInfo = React.lazy(() => import("@/pages/ele-way/9-Car-Info"));
const WayCreateList = React.lazy(() => import("@/pages/ele-way/Create-List"));
const WayList = React.lazy(() => import("@/pages/ele-way/List"));
const PayPage = React.lazy(() => import("@/pages/pay-page"));
const BalanceAdmin = React.lazy(() => import("@/pages/system-admin/balance-admin"));
const CreateChildAdmin = React.lazy(() => import("@/pages/system-admin/create-child-admin"));
const InfoAdmin = React.lazy(() => import("@/pages/system-admin/info-admin"));
const StudyAdminTime = React.lazy(() => import("@/pages/system-admin/study-admin-time"));
const RiskUnit = React.lazy(() => import("@/pages/two-control/01-page"));
const TypeOfAccident = React.lazy(() => import("@/pages/two-control/02-page"));
const LevelControl = React.lazy(() => import("@/pages/two-control/03-page"));
const RiskUnitInspection = React.lazy(() => import("@/pages/two-control/04-page"));
const HazardChecklist = React.lazy(() => import("@/pages/risk-check/01-page"));
const HazardScreeningPlan = React.lazy(() => import("@/pages/risk-check/02-page"));
const HazardInvestigationRecords = React.lazy(() => import("@/pages/risk-check/03-page"));
const HazardAudit = React.lazy(() => import("@/pages/risk-governance/01-page"));
const PotentialModification = React.lazy(() => import("@/pages/risk-governance/02-page"));
const HazardReview = React.lazy(() => import("@/pages/risk-governance/03-page"));
const HazardFiling = React.lazy(() => import("@/pages/risk-governance/04-page"));
const ApplyNumber = React.lazy(() => import("@/pages/risk-governance/05-page"));
const HazardRecord = React.lazy(() => import("@/pages/risk-governance/06-page"));
const IssueInReview = React.lazy(() => import("@/pages/risk-governance/07-page"));
const ReviewFailed = React.lazy(() => import("@/pages/risk-governance/08-page"));
const CarInfoAdmin = React.lazy(() => import("@/pages/car-info-admin/index/index"));
const Line = React.lazy(() => import("@/pages/car-info-admin/line/line"));
const LineAdd = React.lazy(() => import("@/pages/car-info-admin/line-add/index"));
const routes = [
    {
        path:"/",
        exact: true,
        component: Login
    },
    {
        path: "/main",
        component: Main,
        routes:[
            {
                path: "/main",
                exact: true,
                render: () => (
                    <Redirect to="/main/index" />
                )
            },
            {
                path: "/main/index",
                component: Index
            },
            {
                path: "/main/childCompany",
                component: ChildCompany
            },
            {
                path: "/main/PersonnelAdmin",
                component: PersonnelAdmin
            },
            {
                path: "/main/CourseAdmin",
                component: CourseAdmin
            },
            {
                path: "/main/PayPage",
                component: PayPage
            },
            {
                path: "/main/DucRecords",
                component: DucRecords
            },
            {
                path: "/main/PeopleFile",
                component: PeopleFile
            },
            {
                path: "/main/TopicAdmin",
                component: TopicAdmin
            },
            {
                path: "/main/CreatePaper",
                component: CreatePaper
            },
            {
                path: "/main/PaperSend",
                component: PaperSend
            },
            {
                path: "/main/CreateCourse",
                component: CreateCourse
            },
            {
                path: "/main/MyCourse",
                component: MyCourse
            },
            {
                path: "/main/CompanyLib",
                component: CompanyLib
            },
            {
                path: "/main/QajdLib",
                component: QajdLib
            },
            {
                path: "/main/TarningRecord",
                component: TarningRecord
            },
            {
                path: "/main/ApprovalPaper",
                component: ApprovalPaper
            },
            {
                path: "/main/CourseDis",
                component: CourseDis
            },
            {
                path: "/main/CourseAutoDelivery",
                component: CourseAutoDelivery
            },
            {
                path: "/main/ENotify",
                component: ENotify
            },
            {
                path: "/main/SignResponsibility",
                component: SignResponsibility
            },
            {
                path: "/main/Issued",
                component: Issued
            },
            {
                path: "/main/ExamBug",
                component: ExamBug
            },
            {
                path: "/main/ExamGoods",
                component: ExamGoods
            },
            {
                path: "/main/CreateWindControl",
                component: CreateWindControl
            },
            {
                path: "/main/ListWindControl",
                component: ListWindControl
            },
            {
                path: "/main/WayInfo",
                component: WayInfo
            },
            {
                path: "/main/WayReceiver",
                component: WayReceiver
            },
            {
                path: "/main/WayLoader",
                component: WayLoader
            },
            {
                path: "/main/WaySendAddress",
                component: WaySendAddress
            },
            {
                path: "/main/WayTankInfo",
                component: WayTankInfo
            },
            {
                path: "/main/WayGoodsInfo",
                component: WayGoodsInfo
            },
            {
                path: "/main/WayPractitionersInfo",
                component: WayPractitionersInfo
            },
            {
                path: "/main/WayTrailerInfo",
                component: WayTrailerInfo
            },
            {
                path: "/main/WayCarInfo",
                component: WayCarInfo
            },
            {
                path: "/main/WayCreateList",
                component: WayCreateList
            },
            {
                path: "/main/WayList",
                component: WayList
            },
            {
                path: "/main/BalanceAdmin",
                component: BalanceAdmin
            },
            {
                path: "/main/CreateChildAdmin",
                component: CreateChildAdmin
            },
            {
                path: "/main/InfoAdmin",
                component: InfoAdmin
            },
            {
                path: "/main/StudyAdminTime",
                component: StudyAdminTime
            },
            {
                path: "/main/EditCourse",
                component: EditCourse
            },
            {
                path: "/main/RiskUnit",
                component: RiskUnit
            },
            {
                path: "/main/TypeOfAccident",
                component: TypeOfAccident
            },
            {
                path: "/main/LevelControl",
                component: LevelControl
            },
            {
                path: "/main/HazardChecklist",
                component: HazardChecklist
            },
            {
                path: "/main/HazardScreeningPlan",
                component: HazardScreeningPlan
            },
            {
                path: "/main/HazardInvestigationRecords",
                component: HazardInvestigationRecords
            },
            {
                path: "/main/HazardAudit",
                component: HazardAudit
            },
            {
                path: "/main/PotentialModification",
                component: PotentialModification
            },
            {
                path: "/main/IssueInReview",
                component: IssueInReview
            },
            {
                path: "/main/HazardReview",
                component: HazardReview
            },
            {
                path: "/main/HazardFiling",
                component: HazardFiling
            },
            {
                path: "/main/ApplyNumber",
                component: ApplyNumber
            },
            {
                path: "/main/HazardRecord",
                component: HazardRecord
            },
            {
                path: "/main/CarInfoAdmin",
                component: CarInfoAdmin
            },
            {
                path: "/main/RiskUnitInspection",
                component: RiskUnitInspection
            },
            {
                path: "/main/ReviewFailed",
                component: ReviewFailed
            },
            {
                path: "/main/cityBus",
                component: cityBus
            },
            {
                path: "/main/Line",
                component: Line
            },
            {
                path: "/main/LineAdd",
                component: LineAdd
            }
        ]
    }
]

export default routes