import { Map } from 'immutable'
import * as actionTypes from './constants'

const dedfaultState = Map({
    personnelList:[]
})

function reducer(state = dedfaultState,action){
    switch(action.type){
        case actionTypes.CHANGE_GET_PERSONNEL:
            return state.set('personnelList',action.data)
        default:
            return state;
    }
}

export default reducer