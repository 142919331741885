import { Map } from 'immutable'
import * as actionTypes from './constants'

const dedfaultState = Map({
    belong:[],  
    industry:[]
})

function reducer(state = dedfaultState,action){
    switch(action.type){
        case actionTypes.CHANGE_GLB_MECHANISM:
            return state.set('belong',action.belong)
        case actionTypes.CHANGE_GLB_GETBELONG:
            return state.set('industry',action.industry)
        default:
            return state;
    }
}

export default reducer