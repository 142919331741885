import React, { memo, Suspense } from 'react';
import { Spin,ConfigProvider  } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import {Provider} from 'react-redux'
import { HashRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from './router'
import store from './store'

export default memo(function App() {
    return (
        <Provider store={store}>
            <HashRouter>
                <ConfigProvider locale={locale}>
                    <Suspense fallback={
                        <div className="d-flex d-flex-center d-flex-items-center w h">
                            <Spin size="large"/>
                        </div>}>
                        {renderRoutes(routes)}
                    </Suspense>
                </ConfigProvider>
            </HashRouter>
        </Provider>
    )
})
